import React, { ReactNode } from "react"
import { graphql, Link } from "gatsby"
import { Layout } from "../components"
import { Helmet } from "react-helmet"

interface TemplateProps {
  data: {
    markdownRemark: {
      html: string
      frontmatter: {
        slug: string
        title: string
      }
    }
  }
}

export default function Template(props: TemplateProps): ReactNode {
  const { frontmatter, html } = props.data.markdownRemark
  return (
    <Layout>
      <Helmet>
        <title>{frontmatter.title} | Papelzinho ™</title>
      </Helmet>

      <section className="container py-4">
        <Link to="../">Voltar</Link>
      </section>

      <section className="container">
        <h1>{frontmatter.title}</h1>
        <div dangerouslySetInnerHTML={{ __html: html }} />
      </section>
    </Layout>
  )
}

export const pageQuery = graphql`
  query ($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        slug
        title
      }
    }
  }
`
